<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-3 v-if="isLoggedIn">
          <h1>Benutzer-Account einrichten</h1>
        <form action="POST" @submit.prevent="registerUser">
          <v-layout column>
            <v-flex>
              <v-text-field
                name="email"
                label="E-Mail"
                id="email"
                type="email"
                :rules="rules.emailRules"
                v-model="authDetails.email"
                required></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                name="name"
                label="Benutzername"
                id="name"
                type="text"
                :rules="rules.notEmptyRules"
                v-model="authDetails.name"
                required></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                name="password"
                label="Passwort"
                id="password"
                type="password"
                v-model="authDetails.password"
                :rules="rules.passRules"
                required></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                name="confirmPassword"
                label="Passwort bestätigen"
                id="confirmPassword"
                type="password"
                required
                ></v-text-field>
            </v-flex>
            <v-flex class="text-xs-center" mt-5>
              <v-btn color="primary" type="submit">Benutzer anlegen</v-btn>
            </v-flex>
            <v-flex class="text-xs-center" mt-5 v-if="confirmed">
              <v-alert
                type="success"
              >Benutzer wurde erstellt.</v-alert>
            </v-flex>
            <v-flex v-if="error" class="text-xs-center" mt-5>
              <v-alert
                type="error"
              >{{error}}</v-alert>
            </v-flex>
          </v-layout>
        </form>
      </v-flex>
      <v-flex xs12 sm6 offset-sm3 mt-3 v-if="!isLoggedIn">
          <h1>Nur angemeldete Benutzer können neue Benutzer hinzufügen</h1>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import gql from 'graphql-tag';
import rules from '../../helpers/rules';

const REGISTER_USER = gql`
mutation createUser ($email: String! $password: String! $name: String!){
  createUser(input:{data:{email: $email password: $password username: $name confirmed: true}}){
    user{id}
  }
}
`
export default {
  name: 'Register',
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    },
  },
  data () {
    return {
      error:null,
      confirmed: false,
      authDetails: {
        name: '',
        email: '',
        password: ''
      },
      rules:rules,
    }
  },
  methods: {
    registerUser: function () {
      this.confirmed = false;
      this.$apollo.mutate({mutation: REGISTER_USER, variables: { ...this.authDetails }}).then(() => {
        this.confirmed = true;
      }).catch((error) => {
        // Error
        console.error(error);
        this.error = "Benutzer konnte nicht erstellt werden. Mehr Details in der Fehlerkonsole.";
      });
    }
  }
}
</script>
